// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bm_kr";
export var caseStudyBackground__lineColor = "bm_kp";
export var caseStudyHead = "bm_kj";
export var caseStudyHead__imageWrapper = "bm_kk";
export var caseStudyProjectsSection = "bm_ks";
export var caseStudyQuote__bgLight = "bm_kt";
export var caseStudyQuote__bgRing = "bm_km";
export var caseStudyVideo = "bm_kB";
export var caseStudyVideo__ring = "bm_kv";
export var caseStudy__bgDark = "bm_kh";
export var caseStudy__bgLight = "bm_kg";